<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Поиск по тикеру"
          :value="tableParams.ticker"
          clearable
          @input="setField('ticker', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-menu
          ref="menu"
          v-model="isOpenDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="Дата"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @input="updateDate"
            />
          </template>
          <v-date-picker v-model="dates" range />
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="duration"
          :items="durationRecommendations"
          label="Срок"
          item-value="value"
          item-text="title"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select v-model="exchange" :items="exchanges" label="Биржа" item-value="uid" item-text="text" />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="potentialProxy"
          :items="potential"
          label="Потенциал"
          multiple
          item-value="value"
          item-text="title"
          @input="setField('initialPotential', $event)"
        >
          <template v-slot:prepend-item>
            <v-list-item @click="togglePotential">
              <v-checkbox v-model="potentialProxy.length === 0" label="Любой" />
            </v-list-item>

            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="capitalizationProxy"
          :items="capitalization"
          label="Капитализация"
          multiple
          item-value="value"
          item-text="title"
          @input="setField('capitalization', $event)"
        >
          <template v-slot:prepend-item>
            <v-list-item @click="toggleCapitalization">
              <v-checkbox v-model="capitalizationProxy.length === 0" label="Любая" />
            </v-list-item>

            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { DateHelper } from "~/core/date";

import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      exchanges: [
        { uid: [], text: "Любая биржа" },
        { uid: "SPBE", text: "СПБ Биржа" },
        { uid: "MISX", text: "Мосбиржа" }
      ],
      durationRecommendations: [
        { value: [], title: "Любой" },
        { value: "less-month", title: "До месяца" },
        { value: "month-or-two", title: "1—2 месяца" },
        { value: "three-to-four-months", title: "3—4 месяца" },
        { value: "five-to-eleven-months", title: "5—11 месяцев" },
        { value: "one-year", title: "Год" }
      ],
      isOpenDate: false,

      potential: [
        { key: 1, value: { min: 3 }, title: "Более 300%" },
        { key: 2, value: { min: 1, max: 3 }, title: "+100% ... +300%" },
        { key: 3, value: { min: 0, max: 1 }, title: "0% ... 100%" },
        { key: 4, value: { min: -0.3, max: 0 }, title: "-30% ... 0%" },
        { key: 5, value: { min: -0.5, max: -0.3 }, title: "-50% ... -30%" },
        { key: 6, value: { max: -0.5 }, title: "Менее -50%" },
        { key: 7, value: "", title: "Отсутствует" }
      ],

      capitalization: [
        { value: "mega", title: "> $200 млрд" },
        { value: "large", title: "$10—$200 млрд" },
        { value: "medium", title: "$2—$10 млрд" },
        { value: "small", title: "$300 млн — $2 млрд" },
        { value: "micro", title: "< $300 млн" }
      ]
    };
  },
  computed: {
    duration: {
      get() {
        return this.tableParams.duration ?? this.durationRecommendations[0];
      },
      set(value) {
        return this.setField("duration", value);
      }
    },
    exchange: {
      get() {
        return this.tableParams.exchange ?? this.exchanges[0];
      },
      set(value) {
        return this.setField("exchange", value);
      }
    },

    potentialProxy: {
      get() {
        return this.tableParams.initialPotential ?? [];
      },
      set(value) {
        return this.setField("initialPotential", value);
      }
    },

    capitalizationProxy: {
      get() {
        return this.tableParams.capitalization ?? [];
      },
      set(value) {
        return this.setField("capitalization", value);
      }
    },

    dates: {
      get() {
        if (this.tableParams.date?.till) {
          return [this.tableParams.date?.from, this.tableParams.date.till];
        } else if (this.tableParams.date?.from) {
          return [this.tableParams.date?.from];
        } else {
          return [];
        }
      },
      set(value) {
        if (value.length === 1) {
          return this.setField("date", { from: value[0] });
        } else if (value.length > 1) {
          return this.setField("date", { from: value[0], till: value[1] });
        }
      }
    },

    dateRangeText() {
      return this.dates.map(date => DateHelper.getHumanFriendlyDate(date)).join(" - ");
    }
  },
  methods: {
    updateDate(value) {
      if (!value) {
        this.setField("date", []);
      }
    },
    togglePotential() {
      this.setField("initialPotential", []);
    },

    toggleCapitalization() {
      this.setField("capitalization", []);
    }
  }
};
</script>
